import React from 'react';
import { Route, Routes as RouterRoutes } from 'react-router-dom'; // Import Routes as RouterRoutes to avoid conflict

import Home from '../pages/Home';
import Catalog from '../pages/Catalog';
import Detail from '../pages/detail/Detail';

// Rename the component to avoid conflict with 'Routes' from 'react-router-dom'
const AppRoutes = () => {
    return (
        <RouterRoutes>
            <Route
                path='/:category/search/:keyword'
                element={<Catalog />}
            />
            <Route
                path='/:category/:id'
                element={<Detail />}
            />
            <Route
                path='/:category'
                element={<Catalog />}
            />
            <Route
                path='/'
                element={<Home />}
            />
        </RouterRoutes>
    );
}

export default AppRoutes;
