import 'swiper/swiper-bundle.min.css';
import './assets/boxicons-2.0.7/css/boxicons.min.css';
import './App.scss';

import { BrowserRouter } from 'react-router-dom';

import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import AppRoutes from './config/Routes';  // Rename this to avoid conflicts

function App() {
    return (
        <BrowserRouter>
            <>
                <Header />
                <AppRoutes />  {/* Use the renamed Routes component */}
                <Footer />
            </>
        </BrowserRouter>
    );
}

export default App;
